// composables/seo.js

export const useSeo = (data) => {
  let script = null;
  if (data.pubDate && data.upDate && data.datePublished) {
    script = [
      {
        type: 'application/ld+json',
        children: JSON.stringify({
          "@context": "https://ziyuan.baidu.com/contexts/cambrian.jsonld",
          "@id": linkTo(data, true),
          "appid": "1583668660766188",
          "title": data.seotitle,
          "description": data.description,
          "pubDate": data.pubDate,
          "upDate": data.upDate,
          "data": {
            "webPage": {
              "pcUrl": data.pcurl,
              "wapUrl": data.wapurl,
              "fromSrc": "考试资料网",
              "datePublished": data.datePublished
            }
          }
        })
      }
    ];
  }
  useHead({
    title: data.seotitle,
    meta: [
      { name: 'description', content: data.description },
      { name: 'keywords', content: data.keywords }
    ],
    script
  })
}
